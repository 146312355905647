export enum GenderEnum {
	MALE = 'MALE',
	FEMALE = 'FEMALE',
}

export enum VerificationTypesEnum {
	IDENTITY = 'IDENTITY',
	PROFILE_PHOTO = 'PROFILE_PHOTO',
	BIO = 'BIO',
}

export enum UserAccountStatusEnum {
	ACTIVE = 'ACTIVE',
	HIDDEN = 'HIDDEN',
	PAUSED = 'PAUSED',
	DELETED = 'DELETED',
	BANNED = 'BANNED',
}

export enum VerifStatusEnum {
	DENIED = 'DENIED',
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
}

export enum AdminUpdateActionEnum {
	WARN = 'WARN',
	REMOVE_BIO = 'REMOVE_BIO',
	CHANGE_GENDER = 'CHANGE_GENDER',
	REQUEST_CERTIF = 'REQUEST_CERTIF',
	REMOVE_CERTIFICATION = 'REMOVE_CERTIFICATION',
}

export enum BanTypeEnum {
	DEVICE_BAN = 'DEVICE_BAN',
	EMAIL_BAN = 'EMAIL_BAN',
}

export enum PhotoGridPositionsEnum {
	CERTIF = 'certif',
	CENTER = 'center',
	RIGHT1 = 'right1',
	RIGHT2 = 'right2',
	BOTTOM1 = 'bottom1',
	BOTTOM2 = 'bottom2',
	BOTTOM3 = 'bottom3',
}
