import { HTMLProps } from 'react';
import { Card, CardFooter, CardTitle } from '@/components/ui/card.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
	getIdPhotoRef,
	getPhotoUrl,
	getTimeSince,
	getUserCoinCount,
	isEmptyString,
} from '@/lib/utils.ts';
import { ICertifications } from '@/lib/interface.ts';
import ProfilePictureItemGrid from '@/components/custom/ProfilePictureItemGrid.tsx';
import routes from '@/lib/routes.tsx';
import { useNavigate } from 'react-router-dom';
import ProfileBottomStats from '@/components/custom/ProfileBottomStats..tsx';

interface Interface {
	item: ICertifications;
	readonly?: boolean;
	onClick?: (item: ICertifications, accept: boolean) => void;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function CertificationItemCard({
	item,
	readonly = false,
	onClick,
	className,
}: Interface) {
	const navigate = useNavigate();
	const coins = getUserCoinCount(
		item.userData?.coins,
		item.userData.diamondWallet?.coins,
	);

	const showProfileDetails = (userId: string) => {
		navigate(`${routes.nav.profileDetails}/${userId}`);
	};

	return (
		<Card className={`self-start mx-2 md:mx-0 ${className}`}>
			{/* Verifs Photos */}
			<div className={'flex justify-center h-[270px] mb-3'}>
				<img
					alt="verification-image"
					src={getPhotoUrl(item?.urls[0])}
					className="w-1/2 md:w-auto max-w-[210px] object-cover rounded-tl-md"
				/>
				<img
					alt="verification-ref"
					src={getIdPhotoRef(item?.verificationSourceImage)}
					className="w-1/2 md:w-auto max-w-[210px] object-cover rounded-tr-md"
				/>
			</div>

			<div className={'flex flex-col items-center gap-y-1'}>
				<CardTitle
					className={'cursor-pointer'}
					onClick={() => showProfileDetails(item?.userData._id)}
				>
					{item?.userData.username}
					{` - ${coins.toLocaleString()}🪙`}
				</CardTitle>

				<ProfileBottomStats
					hideTitle={true}
					className={'mb-3'}
					userId={item?.userData._id}
					deviceBanCount={item?.userData?.deviceBanCount}
					deviceAccountsCount={item?.userData?.deviceAccountsCount}
				/>

				<ProfilePictureItemGrid
					userId={item?.userData._id}
					pictures={item?.userData.pictures}
					imageContainerClassName={'md:w-28'}
				/>
			</div>

			<CardFooter className={'justify-center flex flex-col'}>
				<div className={'flex flex-col gap-2 p-4'}>
					{!isEmptyString(item?.reviewerData?.username) && (
						<span className={'text-center text-sm text-gray-500'}>
							✅ Effectuée par: {item?.reviewerData?.username}
						</span>
					)}
					{!readonly ? (
						<span className={'text-center text-sm text-gray-500'}>
							{getTimeSince(item.createdAt, 'Certification demandée')}
						</span>
					) : (
						<span className={'text-center text-sm text-gray-500'}>
							{getTimeSince(item.updatedAt, 'Certification effectuée')}
						</span>
					)}
				</div>
				{onClick && (
					<div className={'flex flex-row flex-wrap items-center justify-center gap-4'}>
						<Button
							className={'min-w-36'}
							variant={'destructive'}
							onClick={() => onClick(item, false)}
						>
							Refuser
						</Button>
						<Button
							variant={'default'}
							className={'min-w-36'}
							onClick={() => onClick(item, true)}
						>
							Accepter
						</Button>
					</div>
				)}
			</CardFooter>
		</Card>
	);
}
